<template>
  <div class="list-view-2 product-checkout mt-0">
    <!-- Products List -->
    <e-commerce-checkout-step-cart-products :modules="modules" />

    <!-- Checkout Options -->
    <div class="checkout-options">
      <b-card>
        <div class="price-details">
          <h6 class="price-title">
            Purchase Details
          </h6>
          <ul class="list-unstyled">
            <div
              v-for="module in modules"
              :key="module.id"
            >
              <li
                v-for="product in filterCart(module.products)"
                :key="product.id"
                class="price-detail"
              >
                <div class="detail-title">
                  {{ product.name }}
                </div>
                <div class="detail-amt">
                  <div v-if="product.price <= 0">Free</div>
                  <div v-else>Rp {{ convertNumber(product.qty * product.price * (([1, 2, 3, 25].includes(product.id)) ? 1 : qtyVisit)) }}</div>
                </div>
              </li>
            </div>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title">
                Periode
              </div>
              <div class="detail-amt">
                {{ periode }} Month(s)
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Subtotal
              </div>
              <div class="detail-amt">
                Rp {{ convertNumber(totalAmount) }}
              </div>
            </li>
            <li class="price-detail">
              <div class="detail-title">
                Tax ({{ taxPercent }} %)
              </div>
              <div class="detail-amt">
                Rp {{ convertNumber(Math.floor(totalAmount * taxValue)) }}
              </div>
            </li>
          </ul>
          <hr>
          <ul class="list-unstyled">
            <li class="price-detail">
              <div class="detail-title detail-total">
                Total
              </div>
              <div class="detail-amt font-weight-bolder">
                Rp {{ convertNumber(Math.floor(totalAmount * (1 + taxValue))) }}
              </div>
            </li>
          </ul>
          <b-button
            variant="primary"
            block
            @click="$emit('next-step')"
          >
            Checkout
          </b-button>
        </div>

      </b-card>
    </div>
    <b-row>
      <b-col
        lg="4"
        class="pb-2"
      >
        <b-button
          variant="primary"
          @click="goBack"
        >
          Go to Homepage
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton, BCard, BInputGroup, BFormInput, BInputGroupAppend, BRow, BCol,
} from 'bootstrap-vue'
import { makeToast, numberWithCommas } from '@/@fake-db/utils'
import store from '@/store'
import ECommerceCheckoutStepCartProducts from './ECommerceCheckoutStepCartProducts.vue'

export default {
  components: {
    // BSV
    BButton,
    BCard,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BRow,
    BCol,
    // SFC
    ECommerceCheckoutStepCartProducts,
  },
  props: {
    modules: {
      type: Array,
      required: true,
    },
  },
  computed: {
    periode() {
      return this.modules.find(e => e.name === 'Coverage').products.find(e => e.name === 'Periode').qty ?? 1
    },
    totalAmount() {
      return store.state['app-ecommerce'].priceTotal
    },
    taxPercent() {
      return localStorage.getItem('moduleTax') ?? 0.11
    },
    taxValue() {
      return parseFloat(localStorage.getItem('moduleTax') / 100) ?? 0.11
    },
    qtyVisit() {
      return this.modules.find(e => e.name === 'Coverage').products.find(e => e.id === 1).qty ?? 1
    },
  },
  methods: {
    goBack() {
      this.$swal({
        title: 'Warning',
        text: 'Are you sure to go back to Homepage ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            this.$router.push({ name: 'homepage' })
          }
        })
    },
    filterCart(products) {
      return products.filter(e => e.isInCart && e.name !== 'Periode')
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },
  },
}
</script>
