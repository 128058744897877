<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="refFormObserver"
  >
    <b-form
      class="list-view-2 product-checkout-2"
      @submit.prevent="handleSubmit(onSubmit)"
    >

      <!-- Left Form -->
      <b-card no-body>
        <b-card-header class="flex-column align-items-start">
          <b-card-title>Billing Information</b-card-title>
          <b-card-text class="text-muted mt-25">
            Be sure to fill data correctly
          </b-card-text>
        </b-card-header>
        <b-card-body>
          <b-row>

            <!-- Full Name -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Full Name"
                rules="required"
              >
                <b-form-group
                  label="Full Name"
                  label-for="full-name"
                  class="mb-2"
                >
                  <b-form-input
                    id="full-name"
                    v-model="checkoutDetails.user.fullName"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- State -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <b-form-group
                label="Country"
                label-for="country"
              >
                <country-select
                  v-model="checkoutDetails.user.country"
                  class="form-control"
                  :country="checkoutDetails.user.country"
                  top-country="ID"
                  :usei18n="false"
                  :autocomplete="true"
                  :disable-placeholder="true"
                  :country-name="true"
                />
              </b-form-group>
            </b-col>
            <!-- Company Name -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <validation-provider
                #default="validationContext"
                name="Company Name"
                rules="required"
              >
                <b-form-group
                  label="Company Name"
                  label-for="company"
                >
                  <b-form-input
                    id="company"
                    v-model="checkoutDetails.user.company"
                    type="text"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- City -->
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <b-form-group
                label="City / Town"
                label-for="city"
              >
                <region-select
                  v-model="checkoutDetails.user.city"
                  class="form-control"
                  :country="checkoutDetails.user.country"
                  :region="checkoutDetails.user.city"
                  :usei18n="false"
                  :disable-placeholder="true"
                  :country-name="true"
                  :region-name="true"
                />
              </b-form-group>
            </b-col>

            <!-- Email -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                  class="mb-2"
                >
                  <b-form-input
                    id="email"
                    v-model="checkoutDetails.user.email"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Address -->
            <b-col
              cols="12"
              md="6"
            >
              <validation-provider
                #default="validationContext"
                name="Address"
                rules="required"
              >
                <b-form-group
                  label="Address"
                  label-for="address"
                  class="mb-2"
                >
                  <b-form-input
                    id="address"
                    v-model="checkoutDetails.user.address"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Phone -->
            <b-col
              cols="12"
              md="6"
            >
              <b-form-group
                label="Phone Number (Optional)"
                label-for="phone"
                class="mb-2"
              >
                <VuePhoneNumberInput
                  id="phone"
                  v-model="checkoutDetails.user.phone"
                  :required="false"
                  default-country-code="ID"
                  :preferred-countries="['ID']"
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Faktur Pajak"
                label-for="faktur-pajak"
              >
                <b-form-select
                  id="faktur-pajak"
                  v-model="checkoutDetails.user.fakturPajak"
                  :options="[{ value: '0', text: 'Tidak' },
                             { value: '1', text: 'Ya'}]"
                />
              </b-form-group>
            </b-col>

            <!-- Tax Invoice -->
            <b-col
              v-if="checkoutDetails.user.fakturPajak === '1'"
              cols="12"
              md="3"
            >
              <validation-provider
                #default="validationContext"
                name="NPWP"
                :rules="{
                  required: checkoutDetails.user.fakturPajak === '1',
                }"
              >
                <b-form-group
                  label="NPWP"
                  label-for="npwp"
                  class="mb-2"
                >
                  <b-form-input
                    id="npwp"
                    v-model="checkoutDetails.user.npwp"
                    :state="getValidationState(validationContext)"
                    trim
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- referral code -->
            <b-col
              cols="12"
              md="3"
            >
              <b-form-group
                label="Referral Code  (Optional)"
                label-for="referral-code"
                class="mb-2"
              >
                <b-form-input
                  id="referral-code"
                  v-model="checkoutDetails.user.referralCode"
                  trim
                />
              </b-form-group>
            </b-col>
            <!-- Submit Button -->
            <b-col
              cols="12"
              class="item-right"
            >
              <b-button
                variant="primary"
                type="submit"
              >
                Choose Payment
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormInvalidFeedback,
  BFormSelect,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { required, integer } from '@validations'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import formValidation from '@core/comp-functions/forms/form-validation'
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { makeToast } from '@/@fake-db/utils'
import moment from 'moment'

export default {
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // 3rd party
    vSelect,

    // phone input
    VuePhoneNumberInput,
  },
  props: {
    checkoutDetails: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(() => {
    })

    const onSubmit = () => {
      emit('next-step')
    }

    return {

      // Form
      onSubmit,

      // Form Validation
      refFormObserver,
      getValidationState,
      resetForm,

      // FOrm Validators
      required,
      integer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
