<template>
  <div class="list-view product-checkout">

    <!-- Left Card -->
    <b-card no-body>
      <b-card-header class="flex-column align-items-start">
        <b-card-title>Payment Options</b-card-title>
        <b-card-text class="text-muted mt-25">
          Be sure to click on correct payment option
        </b-card-text>
      </b-card-header>
      <b-card-body>
        <app-collapse type="border">
          <app-collapse-item
            title="Bank Transfer"
            icon="HomeIcon"
            :is-visible="true"
          >
            <b-form-radio
              v-model="checkoutDetails.payment.paymentType"
              value="1"
              class="mb-2 mr-2"
            >
              <h5>A/N PT Pitjarus Teknologi</h5>
              <h5>KC JKT Pasar Rebo</h5>
              <h6>MANDIRI : 1290-0102-9090-2</h6>
            </b-form-radio>
          </app-collapse-item>
        </app-collapse>
      </b-card-body>
    </b-card>

    <!-- Right Card -->
    <div class="amount-payable checkout-options">
      <b-card title="Order Summary">
        <h6><b>Invoice : #{{ checkoutDetails.payment.invoice }}</b></h6>
        <hr>
        <ul class="list-unstyled price-details">
          <div
            v-for="module in checkoutDetails.modules"
            :key="module.id"
          >
            <li
              v-for="product in filterCart(module.products)"
              :key="product.id"
              class="price-detail"
            >
              <div class="details-title">
                {{ product.name }}
              </div>
              <div class="detail-amt">
                <!-- <strong>Rp {{ convertNumber(product.qty * product.price * (([1, 2, 3, 25].includes(product.id)) ? 1 : qtyVisit)) }}</strong> -->
                <strong>Rp {{ product.price > 0 ? `Rp ${convertNumber(product.price * (product.maxQty > 1 ? product.qty : 1) * (([1, 2, 3, 25].includes(product.id)) ? 1 : qtyVisit))}` : product.name === 'Periode' ? '' : 'FREE' }}</strong>
              </div>
            </li>
          </div>
        </ul>
        <hr>
        <ul class="list-unstyled price-details">
          <li class="price-detail">
              <div class="detail-title">
                Periode
              </div>
              <div class="detail-amt">
                <strong>{{ periode }} Month(s)</strong>
              </div>
            </li>
          <li class="price-detail">
            <div class="details-title">
              Subtotal
            </div>
            <div class="detail-amt">
              <strong>Rp {{ convertNumber(totalAmount) }}</strong>
            </div>
          </li>
          <li class="price-detail">
            <div class="details-title">
              Tax
            </div>
            <div class="detail-amt">
              <strong>Rp {{ convertNumber(Math.floor(totalAmount*taxValue)) }}</strong>
            </div>
          </li>
        </ul>
        <hr>
        <ul class="list-unstyled price-details">
          <li class="price-detail">
            <div class="details-title">
              Total
            </div>
            <div class="detail-amt">
              <strong>Rp {{ convertNumber(Math.floor(totalAmount*(1+taxValue))) }}</strong>
            </div>
          </li>
        </ul>
        <b-card-footer class="item-right justify-end">
          <b-button
            variant="primary"
            type="submit"
            block
            @click="$emit('submit')"
          >
            Complete Purchase
          </b-button>
        </b-card-footer>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BFormGroup,
  BFormRadio,
  BFormInput,
  BButton,
  BCardFooter,
  BModal, BFormFile, BInputGroup,
} from 'bootstrap-vue'
import moment from 'moment'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import { makeToast, numberWithCommas } from '@/@fake-db/utils'
import store from '@/store'

export default {
  components: {
    // BSV
    BCard,
    BCardHeader,
    BCardFooter,
    BCardTitle,
    BCardText,
    BCardBody,
    BFormGroup,
    BFormRadio,
    BFormInput,
    BButton,
    AppCollapse,
    AppCollapseItem,
    BModal,
    BFormFile,
    BInputGroup,
  },
  props: {
    checkoutDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {

    }
  },
  computed: {
    periode() {
      return this.checkoutDetails.modules.find(e => e.name === 'Coverage').products.find(e => e.name === 'Periode').qty ?? 1
    },
    currentTime() {
      return moment()
    },
    totalAmount() {
      return store.state['app-ecommerce'].priceTotal
    },
    taxValue() {
      return parseFloat(localStorage.getItem('moduleTax') / 100) ?? 0.11
    },
    qtyVisit() {
      return this.checkoutDetails.modules.find(e => e.name === 'Coverage').products.find(e => e.id === 1).qty ?? 1
    },
  },
  methods: {
    startCallBack(x) {
      console.log(x)
    },
    endCallBack(x) {
      console.log(x)
    },
    filterCart(products) {
      return products.filter(e => e.isInCart && e.name !== 'Periode')
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },

    imgUpload(e) {
      const img = e.target.img || e.dataTransfer.img
      if (!img.length) return
      this.createImg(img[0])
    },

    createImg(img) {
      // var image = new Image();
      const reader = new FileReader()
      const imgs = this

      reader.onload = e => {
        imgs.image = e.target.result
      }
      reader.readAsDataURL(img)
    },

  },
}
</script>

<style lang="scss" scoped>
#cvv {
  width: auto;
}
</style>
