<template>
  <b-overlay
    variant="white"
    :show="showLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".75"
    rounded="sm"
  >
    <div>
      <form-wizard
        ref="refFormWizard"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        finish-button-text="Submit"
        back-button-text="Previous"
        hide-buttons
        class="ecommerce-application checkout-form-wizard steps-transparent"
      >
        <!-- account detail tab -->
        <tab-content
          title="Cart"
          icon="feather icon-shopping-cart"
        >
          <e-commerce-checkout-step-cart
            :modules="checkoutDetails.modules"
            @next-step="formWizardNextStep"
          />
        </tab-content>

        <!-- address -->
        <tab-content
          title="Billing Information"
          icon="feather icon-home"
        >
          <e-commerce-checkout-step-address
            :checkout-details="checkoutDetails"
            @next-step="formWizardNextStep"
          />
        </tab-content>

        <!-- social link -->
        <tab-content
          title="Payment"
          icon="feather icon-credit-card"
        >
          <e-commerce-checkout-step-payment
            :checkout-details="checkoutDetails"
            @submit="formSubmitted"
          />
        </tab-content>
      </form-wizard>
      <b-modal
        id="modal-complete-payment"
        ref="modal-payment"
        centered
        title="Verification Process"
        block
        hide-footer
        @click="submitPayment"
      >
        <b-overlay
          variant="white"
          :show="showLoading"
          spinner-variant="primary"
          blur="0"
          opacity=".75"
          rounded="sm"
        >
          <h3 class="font-weight-bolder">
            Please Check Your Email
          </h3>
          <h5 class="mb-2">
            We've sent a verification code to {{ checkoutDetails.user.email }}
          </h5>
          <validation-observer ref="validationCode">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Enter Your Code Here ..."
                rules="required"
                vid="verification"
              >
                <b-form-input
                  v-model="checkoutDetails.user.verificationCode"
                  :state="errors.length > 0 ? false:null"
                  placeholder="Enter Your Code Here ..."
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </validation-observer>
          <h5 class="mt-2">
            Didn't Get a Code? <a
              href="#"
              @click="formSubmitted"
            >Please Click Here To Resend Code</a>
          </h5>
          <b-button
            class="mt-2"
            block
            @click="submitPayment"
          >
            Confirm & Pay
          </b-button>
        </b-overlay>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { ref } from '@vue/composition-api'
import { makeToast } from '@/@fake-db/utils'
import {
  BButton,
  BCard, BCardBody,
  BCardFooter,
  BCardHeader, BCardText, BCardTitle,
  BFormFile,
  BFormGroup,
  BFormInput, BInputGroup,
  BModal, BOverlay,
  VBModal,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
import store from '@/store'
import ECommerceCheckoutStepAddress from './ECommerceCheckoutStepAddress.vue'
import ECommerceCheckoutStepCart from './ECommerceCheckoutStepCart.vue'
import ECommerceCheckoutStepPayment from './ECommerceCheckoutStepPayment.vue'

export default {
  components: {
    // 3rd Party
    FormWizard,
    TabContent,
    BFormGroup,
    BFormInput,
    BButton,
    BModal,
    BOverlay,

    // Validation
    ValidationProvider,
    ValidationObserver,

    // SFC
    ECommerceCheckoutStepCart,
    ECommerceCheckoutStepAddress,
    ECommerceCheckoutStepPayment,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      showLoading: false,
    }
  },
  created() {
    this.checkoutDetails.modules = JSON.parse(localStorage.getItem('modulesCart'))
    if (!this.checkoutDetails.modules) {
      this.$router.replace({ name: 'homepage' })
        .then(() => {
          makeToast(this, 'put your cart first !', 'AlertTriangleIcon', 'danger')
        })
    }
  },
  methods: {
    formSubmitted() {
      this.$swal({
        title: 'Be sure to fill data correctly',
        html: `<br><div style="text-align: justify"><b>After you are done filling out the payment form, We will send you all the details by email.</b>
<br>
<b>Contact person : </b><a href="mailto:info@pitjarus.com">info@pitjarus.com</a></div>`,
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.showLoading = true
          useJwt.jwtPost('/console/payments/send-verification-code', { email: this.checkoutDetails.user.email })
            .then(response => {
              this.showLoading = false
              makeToast(this, 'Email Successfully Sent', 'MailIcon', 'success')
              this.$refs['modal-payment'].show()
            })
            .catch(e => {
              this.showLoading = false
              const message = e.response?.data?.error ?? e.response?.data?.error?.message ?? e.response.statusText ?? e.message
              if (e.response.status === 400) {
                this.$refs['modal-payment'].show()
              }
              makeToast(this, message, 'AlertTriangleIcon', 'danger')
            })
        }
      })
    },

    submitPayment() {
      const totalAmount = store.state['app-ecommerce'].priceTotal
      const taxValue = parseFloat(localStorage.getItem('moduleTax')) ?? 11
      const { modules } = this.checkoutDetails
      let order_details = []
      modules.forEach(mod => {
        let product = mod.products.filter(e => e.isInCart)
        product = product.map(v => ({
          module_id: v.id, amount: v.price * v.qty, price: v.price, quantity: v.qty, discount: v.discount, is_mandatory: v.mandatory,
        }))
        order_details = order_details.concat(order_details, product)
      })
      const payload = {
        order: {
          amount: totalAmount,
          final_amount: Math.round(totalAmount * (1 + taxValue / 100)),
          tax_rate: taxValue,
        },
        order_details,
        user: {
          fullname: this.checkoutDetails.user.fullName,
          company_name: this.checkoutDetails.user.company,
          email: this.checkoutDetails.user.email,
          phone: this.checkoutDetails.user.phone,
          country: this.checkoutDetails.user.country,
          city: this.checkoutDetails.user.city,
          address: this.checkoutDetails.user.address,
          referral_code: this.checkoutDetails.user.referralCode,
          faktur_pajak: this.checkoutDetails.user.fakturPajak,
          npwp: this.checkoutDetails.user.npwp,
          verification_code: this.checkoutDetails.user.verificationCode,
        },
        payment: {
          payment_type_id: this.checkoutDetails.payment.paymentType,
        },
      }
      if (payload.user.phone === '') delete payload.user.phone
      this.showLoading = true
      useJwt.jwtPost('console/order', payload)
        .then(response => {
          this.showLoading = false
          this.checkoutDetails.user.verificationCode = ''
          makeToast(this, 'You Have Successfully Submitted Module Form', 'EditIcon', 'success')
          localStorage.removeItem('modulesCart')
          localStorage.removeItem('modulesTax')
          this.$router.replace({ name: 'success-order' })
        })
        .catch(e => {
          this.showLoading = false
          const message = e.response?.data?.error ?? e.response?.data?.error?.message ?? e.response.statusText ?? e.message
          if (e.response.status === 409) {
            localStorage.removeItem('modulesCart')
            localStorage.removeItem('modulesTax')
            this.$router.replace({ name: 'homepage' })
              .then(() => {
                makeToast(this, message, 'AlertTriangleIcon', 'danger')
              })
          } else if (e.response.status === 406) {
            // this.$refs['modal-payment'].hide()
            // this.$refs['refFormWizard'].value.prevTab()
            makeToast(this, 'You entered wrong or invalid code! Try Again!', 'AlertTriangleIcon', 'danger')
            this.$refs.validationCode.setErrors({ verification: 'Your Entered Code was Invalid or Wrong! Try Again!' })
            // this.$refs.validationCode.validate().then(success => {
            //   if (success) {
            //     // eslint-disable-next-line
            //     alert('form submitted!')
            //   }
            // })
          } else if (e.response.status === 400) {
            this.$refs['modal-payment'].hide()
            // this.$refs['refFormWizard'].value.prevTab()
            makeToast(this, message, 'AlertTriangleIcon', 'danger')

            // this.$refs.validationCode.validate().then(success => {
            //   if (success) {
            //     // eslint-disable-next-line
            //     alert('form submitted!')
            //   }
            // })
          } else {
            makeToast(this, message, 'AlertTriangleIcon', 'danger')
          }
        })
    },

    infoSuccesOrder() {
      this.$swal({
        title: 'Code Has Been Verified!',
        html: '<strong>Check Your Email!</strong> <br> We Have Been Send Username and Password for Your Login Information!',
        timer: 10000,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
  setup() {
    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
    }

    // ? This is just dummy details
    const checkoutDetails = ref({
      modules: [],
      user: {
        fullName: '',
        company: '',
        email: '',
        phone: '',
        country: 'Indonesia',
        city: 'Jakarta Raya',
        address: '',
        referralCode: null,
        fakturPajak: 0,
        npwp: '',
        verificationCode: '',
      },
      payment: {
        paymentType: 1,
      },
      order: {
        totalAmount: 0,
      },
    })

    return {
      refFormWizard,
      formWizardNextStep,
      // Dummy Details
      checkoutDetails,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss" scoped>
.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
