<template>

  <div class="checkout-items">
    <div
      v-for="module in modules"
      :key="module.id"
    >
      <b-card
        v-for="product in filterCart(module.products)"
        :key="product.id"
        class="ecommerce-card"
        no-body
      >

        <!-- Product Image -->
        <div class="item-img">
          <b-link>
            <b-img-lazy
              :src="product.image_url"
              :alt="`${product.name}-${product.id}`"
            />
          </b-link>
        </div>

        <!-- Product Details: Card Body -->
        <b-card-body>
          <div class="item-name">
            <h6 class="mb-2">
              {{ product.name }}
            </h6>
          </div>
          <div v-if="product.maxQty > 1">
            <b-card-text class="item-description">
              <b-form-input
                v-model="product.qty"
                type="range"
                :min="product.isInCart ? 1 : 0"
                :max="product.maxQty"
                @input="updatePrice"
              />
            </b-card-text>
            <b-card-text class="w-100">
              <validation-provider
                #default="{ errors }"
                :name="product.name"
                :rules="{
                  required: product.isInCart || product.mandatory,
                  integer: true,
                  between:[product.isInCart ? 1 : 0,product.maxQty],
                }"
              >
                <b-form-group
                  label-cols="12"
                  label-cols-lg="3"
                  content-cols="3"
                  content-cols-lg="2"
                  label-size="lg"
                >
                  <template slot="label">
                    {{ `Number of ${product.shortName}` }}
                  </template>
                  <b-form-input
                    :id="`input-${module.id}-${product.id}`"
                    v-model="product.qty"
                    type="number"
                    :state="errors.length > 0 ? false:null"
                    @input="updatePrice"
                  />
                </b-form-group>
                <small
                  :class="[`text-danger`, product.isInCart ? `text-lighten-3 font-weight-bold` : null]"
                >{{ errors[0] }}</small>
              </validation-provider>
            </b-card-text>
          </div>
          <!-- Product Options/Actions -->
          <b-row class="w-100">
            <b-col
              lg="6"
              sm="12"
            >
              <h4>
                {{ product.price > 0 ? `Rp ${convertNumber(product.price * (product.maxQty > 1 ? product.qty : 1) * (([1, 2, 3, 25].includes(product.id)) ? 1 : qtyVisit))}` : product.name === 'Periode' ? '' : 'FREE' }}
              </h4>
            </b-col>
            <b-col
              v-if="!product.mandatory"
              lg="6"
              sm="12"
              class="item-right"
            >
              <b-button
                variant="light"
                class="remove-wishlist bg-primary text-white"
                @click="removeCart(module,product)"
              >
                <feather-icon
                  icon="XIcon"
                  class="mr-50"
                />
                <span>Remove</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>
  </div>

</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BImgLazy, BButton, BBadge, BFormSpinbutton, BCardText, BFormInput, BFormGroup, BRow, BCol,
} from 'bootstrap-vue'
import { makeToast, numberWithCommas, updatePrice } from '@/@fake-db/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BCardBody,
    BLink,
    BImg,
    BImgLazy,
    BButton,
    BBadge,
    BFormSpinbutton,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    modules: {
      type: Array,
      required: true,
    },
  },
  computed: {
    qtyVisit() {
      return this.modules.find(e => e.name === 'Coverage').products.find(e => e.id === 1).qty ?? 1
    }
  },
  methods: {
    updatePrice() {
      return updatePrice(this)
    },
    filterCart(products) {
      return products.filter(e => e.isInCart)
    },
    convertNumber(x) {
      return numberWithCommas(x)
    },
    removeCart(module, product) {
      this.$swal({
        title: 'Warning',
        text: 'Are you sure to remove ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, remove it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.modules.find(e => e.id === module.id).products.find(e => e.id === product.id).isInCart = false
          localStorage.setItem('modulesCart', JSON.stringify(this.modules))
          this.updatePrice()
          this.$swal({
            icon: 'success',
            title: 'Removed!',
            text: 'Your item has been removed.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
